import config from "../../environment";
import { callApi, getToken } from "../utils";



export const getAllUser = async () => {
  const token = await getToken()
  const url = `${config.apiUrl}/api/v1/users`;
  const json = await callApi({
    url: url,
    method: "GET",
    token: token,
    body: null,
  });
  return json;
};


// MY PROFILE
export const getUserProfile = async () => {
  const token = await getToken()
  const url = `${config.apiUrl}/api/v1/users/get/me`;
  const json = await callApi({
    url: url,
    method: "GET",
    token: token,
    body: null,
  });
  return json;
};

// MY BUSINESS
export const getUserBusiness = async () => {
  const token = await getToken()
  const url = `${config.apiUrl}/api/v1/users/get/bussines`;
  const json = await callApi({
    url: url,
    method: "GET",
    token: token,
    body: null,
  });
  return json;
};

// GET USERS
export const getUsersByBusiness = async (_id: number) => {
  const token = await getToken()


  const url = `${config.apiUrl}/api/v1/users/get/byBussines/${_id}`;
  const json = await callApi({
    url: url,
    method: "GET",
    token: token,
    body: null,
  });
  return json;
};


export const getDetailChat = async (body: any) => {
  const token = await getToken()
  const url = `${config.apiUrl}/api/v1/report/user/history/byId`;
  const json = await callApi({
    url: url,
    method: "POST",
    token: token,
    body: body,
  });
  return json;
};


export const CreateUserApi = async (body: any) => {
  const token = await getToken()
  const url = `${config.apiUrl}/api/v1/users/create`;
  const json = await callApi({
    url: url,
    method: "POST",
    token: token,
    body: body,
  });
  return json;
}

export const EditUSerApi = async (body: any, userId: number) => {
  const token = await getToken()
  const url = `${config.apiUrl}/api/v1/users/${userId}`;
  const json = await callApi({
    url: url,
    method: "PUT",
    token: token,
    body: body,
  });
  return json;
}


export const DeleteUserApi = async (userId: number) => {
  const token = await getToken()
  const url = `${config.apiUrl}/api/v1/users/${userId}`;
  const json = await callApi({
    url: url,
    method: "DELETE",
    token: token,
    body: null,
  });
  return json;
}

export const registerPushToken = async (body: any) => {
  const token = await getToken()
  const url = `${config.apiUrl}/api/v1/users/onesignal`;
  const json = await callApi({
    url: url,
    method: "POST",
    token: token,
    body: body,
  });
  return json;
}

export const userUpdateRobin = async (userId: number, body: any) => {
  const token = await getToken()
  const url = `${config.apiUrl}/api/v1/users/robin/${userId}`;
  const json = await callApi({
    url: url,
    method: "POST",
    token: token,
    body: body,
  });
  return json;
}




