// config.ts
const config = {
    apiUrl: 'https://api.flurix.ai',
    apiKey: process.env.REACT_APP_API_KEY || '',
    imageKitUrl: process.env.REACT_APP_IMAGE_KIT_URL_ENDPOINT || '',
    imageKitPublicKey: process.env.REACT_APP_IMAGE_KIT_PUBLIC_KEY || '',
  };



  export default config;
  