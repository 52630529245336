import React, { createContext, useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { IRobot, RobotContextType } from "../utils/types";
import { SuperDescripter } from "../utils/superapis";

export const RobotContext = createContext<RobotContextType>({
  selectedRobot: {
    id: null,
    name: null,
    business_id: null,
  },
  setSelectedRobot: () => { },
  handleSetRobot: () => { },
});

const RobotContextProvider = ({ children }: { children: React.ReactNode }) => {

  let token = localStorage.getItem("aet");
  let businessId = localStorage.getItem("business_id");
  let robotId = localStorage.getItem("robot_id");
  let robotName = localStorage.getItem("robot_name");

  const [selectedRobot, setSelectedRobot] = useState<IRobot>({
    id: robotId || null,
    name: robotName || null,
    business_id: businessId || null,
  });

  useEffect(() => {

    if (token && typeof token === "string") {
      setSelectedRobot({
        id: robotId || null,
        name: robotName || null,
        business_id: businessId || null,
      });
    }
  }, [token]);

  const handleSetRobot = (data: IRobot) => {

    if (token && typeof token === "string") {


      setSelectedRobot(data);
    } else {
      toast.error("You need to login first");
      setSelectedRobot({
        id: null,
        name: null,
        business_id: null,
      });
      window.location.href = "/auth/login"
    }
  };

  return (
    <RobotContext.Provider
      value={{ selectedRobot, setSelectedRobot, handleSetRobot }}
    >
      {children}
    </RobotContext.Provider>
  );
};



export function useRobotContext() {
  return useContext(RobotContext);
}
export default RobotContextProvider;
