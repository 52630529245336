// Types
import { AlertColor, SnackbarOrigin } from "@mui/material";
import { createSlice } from "@reduxjs/toolkit";

interface State extends SnackbarOrigin {
  open: boolean;
  text: string;
  role?: AlertColor;
}

// Initial state
const initialState: State = {
  open: false,
  vertical: 'bottom',
  horizontal: 'right',
  text: "",
  role: "success"
};

// ==============================|| SLICE - CASES ||============================== //

const toast = createSlice({
  name: "case",
  initialState,
  reducers: {
    toogle(state, action) {
      state.open = action.payload.state;
      state.text = action.payload.text ?? "";
      state.role = action.payload.role ?? "";
    },
  },
});

export default toast.reducer;

export const { toogle } = toast.actions;
