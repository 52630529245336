import React, { createContext, useContext, useEffect, useState } from 'react';
import OneSignal from 'react-onesignal';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { set } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { registerPushToken } from '../services/users';

interface NotificationContextProps {
    subscribeToNotifications: () => Promise<void>;
    notification: string | null;
}

const NotificationContext = createContext<NotificationContextProps | undefined>(undefined);

export const NotificationProvider = ({ children }: { children: React.ReactNode }) => {
    const [notification, setNotification] = useState<any | null>({
        message: '',
        additionalData: {},
    });
    const [open, setOpen] = useState(false);
    const [initOnesignal, setInitOnesignal] = useState(false);

    const subscribeToNotifications = async () => {

        if (initOnesignal) {
            // console.log('El usuario ya está suscrito a las notificaciones');
            return; // Si ya está suscrito, no hacer nada
        }

        await OneSignal.init({
            appId: "16b37cc8-3576-4123-be29-095b189c4f11", // Reemplaza con tu APP_ID de OneSignal
            allowLocalhostAsSecureOrigin: true, // Permitir localhost como origen seguro
            notifyButton: {
                enable: true, // Habilitar botón flotante de notificaciones
            },
            welcomeNotification: {
                title: "¡Bienvenido!",
                message: "Gracias por suscribirte a las notificaciones",
            },
            autoRegister: true, // Registrarse automáticamente
            serviceWorkerPath: 'OneSignalSDKWorker.js',
            serviceWorkerUpdaterPath: 'OneSignalSDKUpdaterWorker.js',
        });

        setInitOnesignal(true)


        OneSignal.Slidedown.promptPush();
        OneSignal.Debug.setLogLevel('trace');
        OneSignal.login("elias");



        OneSignal.Notifications.addEventListener('permissionChange', (permission) => {
            // console.log('Permiso de notificación ha cambiado:', permission);
        });

        // Manejar eventos de notificación
        OneSignal.Notifications.addEventListener('foregroundWillDisplay', (event) => {
            // console.log('Notificación recibida:', event.notification.body);
            const message = event.notification.body || 'Nueva notificación recibida';
            const additionalData = event.notification.additionalData


            setNotification({
                message,
                additionalData,
            });
            setOpen(true);
            event.preventDefault();
        });

        OneSignal.Notifications.addEventListener('click', (event) => {
            if (event.result.url) {
                window.open(event.result.url, '_blank');
            }
        });

        OneSignal.Notifications.addEventListener('dismiss', (event) => {
            // console.log('Notificación descartada:', event.notification);
        });


        const subscription = OneSignal.User.PushSubscription;

        if (subscription.id) {
            //enviamos dato a nuestro backend

            await registerPushToken({ token: subscription.id })

        } else {
            // console.log('Usuario no está suscrito.');
        }

        // Escuchar cambios en la suscripción
        subscription.addEventListener('change', (change) => {
            // console.log('Cambio en la suscripción:', change);
        });

        // Suscribir al usuario manualmente
        if (!subscription.optedIn) {
            await subscription.optIn();
            // console.log('Usuario suscrito a notificaciones push.');
        }

    };





    useEffect(() => {
        subscribeToNotifications();

        return () => {
            OneSignal.Notifications.removeEventListener('foregroundWillDisplay', () => { });
            OneSignal.Notifications.removeEventListener('click', () => { });
            OneSignal.Notifications.removeEventListener('dismiss', () => { });
        };
    }, []);

    const handleClose = () => {
        setOpen(false);
    };

    const navigate = useNavigate();

    return (
        <NotificationContext.Provider value={{ subscribeToNotifications, notification }}>
            {children}
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                onClick={() => {
                    const hashedId = notification.additionalData;

                    navigate(`/app/chats/${hashedId.clientId}`);
                    handleClose();
                }}
            >
                <Alert onClose={handleClose} severity={notification.additionalData.severity ? notification.additionalData.severity : "error"} variant='filled' sx={{ width: '100%' }}>
                    {notification.message}
                </Alert>
            </Snackbar>
        </NotificationContext.Provider>
    );
};

export const useNotification = () => {
    const context = useContext(NotificationContext);
    if (!context) {
        throw new Error('useNotification debe usarse dentro de NotificationProvider');
    }
    return context;
};
